@font-face {
  font-family: 'Neue Haas Grotesk Text Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Neue Haas Grotesk Text Pro'), url('https://fonts.cdnfonts.com/s/14415/NHaasGroteskTXPro-55Rg.woff') format('woff');
}
@font-face {
  font-family: 'Neue Haas Grotesk Text Pro';
  font-style: italic;
  font-weight: 400;
  src: local('Neue Haas Grotesk Text Pro'), url('https://fonts.cdnfonts.com/s/14415/NHaasGroteskTXPro-56It.woff') format('woff');
}
@font-face {
  font-family: 'Neue Haas Grotesk Text Pro';
  font-style: normal;
  font-weight: 700;
  src: local('Neue Haas Grotesk Text Pro'), url('https://fonts.cdnfonts.com/s/14415/NHaasGroteskTXPro-75Bd.woff') format('woff');
}
@font-face {
  font-family: 'Neue Haas Grotesk Text Pro';
  font-style: italic;
  font-weight: 700;
  src: local('Neue Haas Grotesk Text Pro'), url('https://fonts.cdnfonts.com/s/14415/NHaasGroteskTXPro-76BdIt.woff') format('woff');
}

@font-face {
  font-family: 'TT Hoves';
  src: local('TT Hoves Regular'), local('TT-Hoves-Regular'),
      url('./fonts/TTHoves-Regular/TTHoves-Regular.woff2') format('woff2'),
      url('./fonts/TTHoves-Regular/TTHoves-Regular.woff') format('woff'),
      url('./fonts/TTHoves-Regular/TTHoves-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'TT Hoves Medium';
  src: local('TT Hoves Medium'), local('TT-Hoves-Medium'),
      url('./fonts/TTHoves-Medium/TTHoves-Medium.woff2') format('woff2'),
      url('./fonts/TTHoves-Medium/TTHoves-Medium.woff') format('woff'),
      url('./fonts/TTHoves-Medium/TTHoves-Medium.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

body {
  background: #000;
  font-family: "TT Hoves";
  color: #fff;
  font-size: 24px;
  overflow: hidden;
}
a {
  color: #fff;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
 
}



.spinEffect{
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}
.spinEffectOut{
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
}

.rotateIn {
  animation: rotate-in-keyframes 300ms;
 }
 
 @keyframes rotate-in-keyframes {
  from {
   transform: rotate(0deg);
  }
 
  to {
   transform: rotate(45deg);
  }
 }

 .rotateOut {
  animation: rotate-out-keyframes 300ms;
 }
 
 @keyframes rotate-out-keyframes {
  from {
   transform: rotate(45deg);
  }
 
  to {
   transform: rotate(0deg);
  }
 }


.aboutM-enter {
  opacity: 0.01;
}
.aboutM-enter-active {
  opacity: 1;
  -webkit-timing-function: ease-in;
  transition-duration: 400ms;
  transition: ease-in 400ms;
}
.aboutM-exit {
  opacity: 1;
}
.aboutM-exit-active {
  opacity: 0.01;
  -webkit-timing-function: ease-out;
  transition-duration: 400ms;
  transition: ease-out 400ms;
}

a:visited {
  color: #fff;
}
/* a:hover {
  color: #fff;
} */

#button-prev {
  color: rgb(120, 120, 120);
  text-decoration: none;
}

#button-prev:hover {
  color: rgb(120, 120, 120);
  visibility: visible;
}

#button-next {
  color: rgb(120, 120, 120);
}
#button-next:hover {
  color: rgb(120, 120, 120);
  visibility: visible;
}

#button-about:hover {
  color: rgb(120, 120, 120);
}

@media only screen and (max-width: 575px) {
  body {
    font-family: "TT Hoves";
    font-size: 21px;
    
  }
}
